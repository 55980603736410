import { Component, OnInit } from '@angular/core';
import { WebPhoneService } from '../webphone.service';

@Component({
  selector: 'app-display',
  templateUrl: './display.component.html',
  styleUrls: ['./display.component.sass']
})
export class DisplayComponent implements OnInit {
  number: string = "";

  constructor(private WebPhoneService: WebPhoneService) { 
    this.WebPhoneService.webPhoneEv.subscribe(calledNum => this.number = calledNum)
  }

  ngOnInit(): void {
  }

}
