<!-- <style>
  .content {
    display: flex;
    margin: 82px auto 32px;
    padding: 0 16px;
    max-width: 960px;
    flex-direction: column;
    align-items: center;
  }
</style> -->

<!-- <div class="container-fluid" role="main">
  <div class="row">
    <div class="col-lg-8">
    </div>
    <div class="col-lg-4">
      <app-webphone></app-webphone>
    </div>
  </div>


</div> -->
<div class="container-fluid">
  <div class="row">
    <div class="col-lg-2 no-padding">
      <div class="nav-container">
        <nav class="navbar navbar-expand-lg navbar-light">
          <div class="container-fluid">
            <a class="navbar-brand" href="#">
              <img src="../assets/img/logo1.png" alt="Logo" class="d-inline-block align-text-top">
              Juratech Call Center
            </a>
            <button class="navbar-toggler" type="button" (click)="collapseMenu()">
              <span class="navbar-toggler-icon"></span>
            </button>
          </div>
          <div class="collapse navbar-collapse" [ngClass]="{'show': collapseBool}">
            <ul class="nav flex-column">
              <li class="nav-item">
                <a class="nav-link" routerLink="/agent/client-info">
                  Agent
                </a>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="javascript:void(0)" (click)="supervisorDropdown()">
                  Supervisor
                </a>
                <div class="dropdown-menu" [ngClass]="{'dropdown-display': supervisorDropdownBool}">
                  <a class="dropdown-item" href="javascript:void(0)">
                    Entiteti
                  </a>
                </div>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="javascript:void(0)" (click)="adminDropdown()">
                  Admin
                </a>
                <div class="dropdown-menu" [ngClass]="{'dropdown-display': adminDropdownBool}">
                  <a class="dropdown-item" routerLink="/admin/users">
                    Korisnici
                  </a>
                  <a class="dropdown-item" routerLink="/admin/campaign">
                    Kampanje
                  </a>
                  <a class="dropdown-item" routerLink="/admin/actions">
                    Akcije
                  </a>
                  <a class="dropdown-item" routerLink="/admin/phonebook">
                    Imenik
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
    <div class="col-lg-10 main-part">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>