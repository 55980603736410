<div class="dialer">
    <table>
        <tr>
            <td (click)=onDigitPressEv(1)>1</td>
            <td (click)=onDigitPressEv(2)>2</td>
            <td (click)=onDigitPressEv(3)>3</td>
        </tr>
        <tr>
            <td (click)=onDigitPressEv(4)>4</td>
            <td (click)=onDigitPressEv(5)>5</td>
            <td (click)=onDigitPressEv(6)>6</td>
        </tr>
        <tr>
            <td (click)=onDigitPressEv(7)>7</td>
            <td (click)=onDigitPressEv(8)>8</td>
            <td (click)=onDigitPressEv(9)>9</td>
        </tr>
        <tr>
            <td (click)=onDigitPressEv(11)>*</td>
            <td (click)=onDigitPressEv(0)>0</td>
            <td (click)=onDigitPressEv(12)>#</td>
        </tr>
    </table>
    <table>
        <tr>
            <td (click)=onCPress()>C</td>
            <td (click)=onClearPressEv()>CLEAR</td>
        </tr>
        <tr>
            <td (click)=onCallPressEv() class="">
                <fa-icon [icon]="faPhoneAlt"></fa-icon>
            </td>
            <td (click)=onHangupCallEv()>
                <fa-icon [icon]="faPhoneSlash"></fa-icon>
            </td>
        </tr>
        <tr>
            <td (click)=onMutePressEv()>
                <!-- {{mutedBool ? "MUTE" : "UNMUTE"}} -->
                <fa-icon *ngIf="mutedBool" [icon]="faMicrophoneAltSlash"></fa-icon>
                <fa-icon *ngIf="!mutedBool" [icon]="faMicrophoneAlt"></fa-icon>
            </td>
            <td (click)=onBlindTransfer()>
                <!-- TRANSFER -->
                <fa-icon [icon]="faPeopleArrows"></fa-icon>
            </td>
            <!-- <td (click)=onTransferEv()>TRANSFER</td> -->
        </tr>
        <!-- <tr>
            <td (click)=onAttendedTransfer()>ATTENDED</td>
        </tr> -->
        <tr>
            <td (click)=onHoldPressEv()>
                <!-- HOLD -->
                <fa-icon [icon]="faPause"></fa-icon>
            </td>
            <td (click)=onResumeCallEv()>
                <!-- RESUME -->
                <fa-icon [icon]="faPlay"></fa-icon>
            </td>
        </tr>
        <tr>
            <td (click)=onVolumeEv(false)>
                <!-- VOLUME DOWN -->
                <fa-icon [icon]="faVolumeDown"></fa-icon>
            </td>
            <td (click)=onVolumeEv(true)>
                <!-- VOLUME UP -->
                <fa-icon [icon]="faVolumeUp"></fa-icon>
            </td>
        </tr>
        <tr>
            <td>VOLUME: {{volumeNum == 1 ? "MAX" : volumeNum == 0 ? "MUTE" : volumeNum * 10}}</td>
        </tr>
        <tr>
            <td (click)="onDummyNumber()">DUMMY NUMBER</td>
            <td (click)="onDummyQueue()">DUMMY QUEUE</td>
        </tr>
    </table>
</div>