import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { WebPhoneService } from './webphone.service'

// import { Inviter, Session, UserAgent } from 'sip.js/lib/api';

// We need this sipjs stuff
import {
  Inviter,
  Registerer,
  SessionState,
  UserAgent,
  UserAgentOptions,
} from "sip.js";

// We need this in order to transfer a call
import { holdModifier } from 'sip.js/lib/platform/web/modifiers/modifiers';
import { DummyService } from './dummy.service';
import { constructOutgoingResponse } from 'sip.js/lib/core';

// import { SimpleUser, SimpleUserOptions } from 'sip.js/lib/platform/web';

@Component({
  selector: 'app-webphone',
  templateUrl: './webphone.component.html',
  styleUrls: ['./webphone.component.sass']
})
export class WebphoneComponent implements OnInit {

  userAgent: UserAgent;
  registrerer: Registerer;
  sip_server: string;
  sip_extension: string;
  inviter: Inviter;
  session: any;
  mutedBool: boolean = true;

  constructor(
    private WebPhoneService: WebPhoneService,
    private dummyService: DummyService
  ) {
    this.WebPhoneService.callNumber.subscribe(num => this.makeSIPCall(num));
    this.WebPhoneService.hangUpCall.subscribe(() => this.hangupCall());
    this.WebPhoneService.holdCall.subscribe(() => this.onHoldCallEv());
    this.WebPhoneService.resumeCall.subscribe(() => this.onResumeCall());
    this.WebPhoneService.muteCallEv.subscribe(() => this.muteCall());
    this.WebPhoneService.volumeCallEv.subscribe(num => this.setCallVolume(num));
    this.WebPhoneService.blindTransfer.subscribe((num) => this.onBlindTransfer(num));
    this.WebPhoneService.attendedTransfer.subscribe((num) => this.onAttendedTransfer(num));
    this.WebPhoneService.dummyNumberEv.subscribe((obj) => console.log(obj));
    this.WebPhoneService.dummyQueueEv.subscribe((obj) => console.log(obj));
    this.dummyService.getPhonebookEntery().then(x => console.log(x))
  };

  ngOnInit() {

    /////////////////// SIP JS ///////////////////
    // Here you fill the data from DB, User Session , whatever...
    this.sip_extension = "701"; // Same as PBX username  - EDIT THIS
    this.sip_server = "10.255.129.16"; // IP address of the SIP server  - EDIT THIS
    const authorizationUsername = this.sip_extension;  // Username from the PBX
    const authorizationPassword = "85a8d90f647e83bdab401bb2585f8ed0"; // Pass from the PBX - EDIT THIS
    const displayName = "WebRTC"; // Display name - your choise

    // Here we set the websocket transport
    const transportOptions = {
      server: "wss://" + this.sip_server + ":8089/ws"
    };
    // We use the static class makeURI to generate URI in the right format
    const uri = UserAgent.makeURI("sip:" + this.sip_extension + "@" + this.sip_server);

    // We use the all the data to create userAgent Options
    const userAgentOptions: UserAgentOptions = {
      uri,
      authorizationPassword,
      authorizationUsername,
      transportOptions,
      displayName,
      userAgentString: "CloudPBX WebRTC", // You can edit this
      allowLegacyNotifications: true,
      delegate: {
        //Event listener for incoming calls
        onInvite: this.onInvite
      }
    };

    // Now we instantiate userAgent & registrerer ( used to register to our server )
    this.userAgent = new UserAgent(userAgentOptions);
    this.registrerer = new Registerer(this.userAgent);

    // If the User Agent has started successfuly we register it to sip server
    this.userAgent.start().then(() => {
      this.registrerer.register()
    }).catch(() => console.log("We couldn't start the user agent. Check UA options"));

  }
  // Helper function to generate uri
  uriHelper(number) {
    return `sip:${number}@${this.sip_server}`
  }


  // Make SIP Call
  isEstablished: boolean = null
  dummysession: any
  makeSIPCall = number => {
    // Generate URI

    this.dummyService.getNextCall()
      .then(
        (x: any) => {
          console.log(x)
          this.WebPhoneService.webPhoneEv.emit(x.phoneNumber)
          const number_uri = UserAgent.makeURI(this.uriHelper(x.phoneNumber));
          // INVITER instance & set Inviter Options
          this.inviter = new Inviter(this.userAgent, number_uri, {
            sessionDescriptionHandlerOptions: {
              constraints: { audio: true, video: false }
            }
          }
          );
          console.log(this.inviter);

          // INVITER Ev listener / state change listener
          this.inviter.stateChange.addListener((newState) => {

            // Statuses
            // 2 - Called
            // 3 - Busy
            // 4 - NoAnswer
            console.log(this.isEstablished)
            console.log(newState)
            switch (newState) {
              case SessionState.Establishing:
                // Session is establishing
                console.log("Calling ...")
                this.isEstablished = false
                setTimeout(() => {
                  if (this.isEstablished == false) {
                    this.dummyService.changeStatus(4, x.id)
                      .then(
                        x => {
                          console.log(x)
                          this.hangupCall()
                          this.cleanUpMedia();
                        }
                      )
                      .catch(
                        e => console.log(e)
                      )
                  }
                }, 20000)
                break;
              case SessionState.Established:
                // Session has been established
                // if established setup audio / video
                this.isEstablished = true
                this.dummyService.changeStatus(2, x.id)
                  .then(
                    x => {
                      console.log(x)
                    }
                  )
                  .catch(
                    e => console.log(e)
                  )
                this.setupRemoteMedia();
                // alert("You are connected!");
                break;
              case SessionState.Terminated:
                this.isEstablished = true
                console.log(this.isEstablished)
                // if terminated destroy audio / video
                this.cleanUpMedia();
                // alert("Your call is terminated!");
                break;
              default:
                break;
            }
          });

          // set session
          this.session = this.inviter;

          // this.inviter.stateChange

          let inviteOptions = {
            requestDelegate: {
              onAccept: (response) => {
                console.log(response);
              },
              onReject: (response) => {
                console.log(response);
              }
            }
          };

          // send INVITE / make call
          this.inviter.invite(inviteOptions)
            .then(
              x => console.log(x)
            )
            .catch(
              e => console.log(e)
            );


        }
      )
      .catch(
        e => console.log(e)
      )
  }

  hangupCall = () => {
    switch (this.session.state) {
      case SessionState.Initial:
      case SessionState.Establishing:
        console.log(this.session)
        if (this.session instanceof Inviter) {
          // An unestablished outgoing session
          this.session.cancel()
            .then(
              x => console.log(x)
            )
            .catch(
              e => console.log(e)
            );
        } else {
          // An unestablished incoming session
          this.session.reject()
            .then(
              x => console.log(x)
            )
            .catch(
              e => console.log(e)
            );
        }
        break;
      case SessionState.Established:
        // An established session
        this.session.bye()
          .then(
            x => console.log(x)
          )
          .catch(
            e => console.log(e)
          );
        break;
      case SessionState.Terminating:
      case SessionState.Terminated:
        // Cannot terminate a session that is already terminated
        break;
    }
  }

  // Incoming call  handler
  onInvite = invitation => {
    // Here we extract Caller ID
    console.log(invitation)
    const cid = invitation.assertedIdentity.displayName;

    this.dummyService.getPhonebook(cid.split('-')[1])
      .then(
        x => {
          console.log(x)
          this.WebPhoneService.dummyNumberEv.emit()
        }
      )
      .catch(
        e => console.log(e)
      )

    // The invitation is our session as this is incoming call and we recieve the session
    this.session = invitation;
    this.session.stateChange.addListener((newState) => {
      switch (newState) {
        case SessionState.Establishing:
          // Session is establishing
          console.log("Calling ...")
          break;
        case SessionState.Established:
          // Session has been established
          this.setupRemoteMedia();
          // alert("You are connected!");
          break;
        case SessionState.Terminated:
          this.cleanUpMedia();
          // alert("Your call is terminated!");
          break;
        default:
          break;
      }
    });


    // Notify the user about the call and Accept or Reject it 
    if (confirm(`You Have a New Call from: ${cid}. Do you accept it?`)) {
      this.session.accept();
    } else {
      this.session.reject();
    }


  }

  // Setup audio / video streams
  setupRemoteMedia = () => {
    const remoteStream = new MediaStream();
    // Grab your audio / video element
    const mediaElement = document.getElementById('audio_remote') as HTMLAudioElement;
    this.session.sessionDescriptionHandler.peerConnection.getReceivers().forEach((receiver) => {
      if (receiver.track) {
        remoteStream.addTrack(receiver.track);
      }
    });
    mediaElement.srcObject = remoteStream;
    mediaElement.play();
  }

  // Stop media on hangup / end call
  cleanUpMedia = () => {
    const mediaElement = document.getElementById('audio_remote') as HTMLAudioElement;
    mediaElement.srcObject = null;
    mediaElement.pause();
  }

  setCallVolume = num => {
    let aud: any = document.getElementById('audio_remote');
    aud.volume = num
  }

  muteCall = () => {
    this.mutedBool = !this.mutedBool
    const pc: any = this.session.sessionDescriptionHandler.peerConnection
    pc.getSenders().forEach((stream: any) => {
      stream.track.enabled = this.mutedBool;
      return;
    })
  }

  //  change SDP and send reInvite to put a call on hold
  onHoldCallEv() {
    this.session.sessionDescriptionHandlerModifiersReInvite = [holdModifier];
    this.session.invite();
  }

  //  send reInvite to resume a call
  onResumeCall() {
    this.session.sessionDescriptionHandlerModifiersReInvite = [];
    this.session.invite();
  }

  // Unatended / Blind Call transfer
  onBlindTransfer(number) {
    if (!this.session) return;

    const target = UserAgent.makeURI(this.uriHelper(number));
    this.session.refer(target);
  }

  // This is not working at the moment
  onAttendedTransfer(number) {
    if (!this.session) return;

    // NOT WORKING RIGHT NOW /////
    // const replacementSession = new Inviter(this.userAgent,UserAgent.makeURI(this.uriHelper(number)),{
    //     sessionDescriptionHandlerOptions: {
    //         constraints: { audio: true, video: false }
    //     }
    // });

    // this.session.refer(replacementSession);


  }

}
