import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent {
  collapseBool: boolean = false
  supervisorDropdownBool: boolean = false
  adminDropdownBool: boolean = false

  collapseMenu(): void {
    this.collapseBool = !this.collapseBool
  }

  adminDropdown(): void{
    this.adminDropdownBool = !this.adminDropdownBool
  }

  supervisorDropdown(): void{
    this.supervisorDropdownBool = !this.supervisorDropdownBool
  }

}
