import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class DummyService {
    constructor(
        private httpClient: HttpClient
    ) { }

    public getPhonebook = (phonenumber: string) => {
        return this.httpClient.get('api/dummy/dummyphonebook/' + phonenumber).toPromise()
    }

    public getQueue = (queueId: string) => {
        return this.httpClient.get('api/dummy/dummyqueue/' + queueId).toPromise()
    }

    public getNextCall = () => {
        return this.httpClient.get('api/callcenter/nextcall').toPromise()
    }

    public changeStatus = (status: number, phonebookId: number) => {
        return this.httpClient.get('api/callcenter/setcallstatus/' + phonebookId + '/' + status).toPromise()
    }

    public getPhonebookEntery = () => {
        return this.httpClient.get('api/callcenter/getphonebookentries').toPromise()
    }
}