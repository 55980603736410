import { Component, OnInit } from '@angular/core';
import { constructOutgoingResponse } from 'sip.js/lib/core';
import { DummyService } from '../dummy.service';
import { WebPhoneService } from '../webphone.service';
import { faPhoneAlt, faMicrophoneAlt, faMicrophoneAltSlash, faPhoneSlash, faPause, faPlay, faVolumeDown, faVolumeUp, faPeopleArrows } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-dialer',
  templateUrl: './dialer.component.html',
  styleUrls: ['./dialer.component.sass']
})
export class DialerComponent implements OnInit {
  faPhoneAlt = faPhoneAlt
  faMicrophoneAlt = faMicrophoneAlt
  faMicrophoneAltSlash = faMicrophoneAltSlash
  faPhoneSlash = faPhoneSlash
  faPause = faPause
  faPlay = faPlay
  faVolumeUp = faVolumeUp
  faVolumeDown = faVolumeDown
  faPeopleArrows = faPeopleArrows

  calledNum: string = '';
  volumeNum: number = 1;
  mutedBool: boolean = true;

  constructor(
    private WebPhoneService: WebPhoneService,
    private dummyService: DummyService
  ) { }

  ngOnInit(): void {
  }

  onDigitPressEv(digit) {
    if (digit == 11)
      digit = "*"
    this.calledNum = this.calledNum.concat(digit);
    this.WebPhoneService.webPhoneEv.emit(this.calledNum);
  }

  onCPress() {
    this.calledNum = this.calledNum.slice(0, -1);
    this.WebPhoneService.webPhoneEv.emit(this.calledNum);
  }

  onClearPressEv() {
    this.calledNum = "";
    this.WebPhoneService.webPhoneEv.emit(this.calledNum);
  }

  onCallPressEv() {
    this.WebPhoneService.callNumber.emit(this.calledNum);
  }

  onHangupCallEv() {
    this.calledNum = "";
    this.WebPhoneService.hangUpCall.emit(true);
    this.WebPhoneService.webPhoneEv.emit(this.calledNum);
  }

  onMutePressEv() {
    this.mutedBool = !this.mutedBool;
    this.WebPhoneService.muteCallEv.emit("");
  }

  onTransferEv() {
    this.WebPhoneService.trasferCallEv.emit("");
  }

  onVolumeEv(bool: boolean) {
    if (bool == true) {
      if (this.volumeNum < 1) {
        this.volumeNum = parseFloat((this.volumeNum + 0.1).toFixed(1))
      }
    } else if (bool == false) {
      if (this.volumeNum > 0) {
        this.volumeNum = parseFloat((this.volumeNum - 0.1).toFixed(1));
      }
    }
    this.WebPhoneService.volumeCallEv.emit(this.volumeNum)
  }

  onHoldPressEv() {
    this.WebPhoneService.holdCall.emit(true);
  }

  onResumeCallEv() {
    this.WebPhoneService.resumeCall.emit(true);
  }

  onBlindTransfer() {
    const number = prompt("Where to transfer?");
    this.WebPhoneService.blindTransfer.emit(number);
  }

  onAttendedTransfer() {
    const number = prompt("Where to transfer?");
    this.WebPhoneService.attendedTransfer.emit(number);
  }


  onDummyNumber() {
    this.dummyService.getPhonebook('0628006905')
      .then(
        x => {
          console.log(x)
          this.WebPhoneService.dummyNumberEv.emit()
        }
      )
      .catch(
        e => console.log(e)
      )
  }

  onDummyQueue() {
    this.dummyService.getQueue('prodaja')
      .then(
        x => {
          console.log(x)
          this.WebPhoneService.dummyQueueEv.emit()
        }
      )
      .catch(
        e => console.log(e)
      )
  }

}
