import { EventEmitter, Injectable } from "@angular/core";


@Injectable({
    providedIn: 'root'
})
export class WebPhoneService {
    webPhoneEv = new EventEmitter<string>();
    callNumber = new EventEmitter<string>();
    hangUpCall = new EventEmitter<any>();
    
    muteCallEv = new EventEmitter<any>();
    volumeCallEv = new EventEmitter<number>();

    holdCall = new EventEmitter<any>();
    resumeCall = new EventEmitter<any>();

    trasferCallEv = new EventEmitter<any>();

    blindTransfer = new EventEmitter <any>();
    attendedTransfer = new EventEmitter <any>();

    dummyNumberEv = new EventEmitter <any>();
    dummyQueueEv = new EventEmitter <any>();
}